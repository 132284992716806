import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import 'semantic-ui-css/semantic.min.css';
import './index.scss';
import '../sass/main.scss';
// @ts-ignore

import favicon from './../images/favicon.png';

import { USMap } from 'career-page-components';
// import { USMap } from '../../../../career-page-components/src/index'

import employee1 from './../images/employee-1.png';
import employee2 from './../images/employee-2.png';
import mestekLogo from './../images/mestek-logo.svg';

import Layout from '../layouts/layout/Layout';
import { createMedia } from '@artsy/fresnel';

// Sections
import HeroSectionComponent from '../components/HeroSection/HeroSection.component';
import TextBlock from '../components/TextBlock/TextBlock.component';
import { Helmet } from 'react-helmet';
import JobAlerts from '../components/JobAlerts/JobAlerts';

const IndexPage: React.FC<PageProps> = () => {
	// init();
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	const menuItems = [
		{ name: 'Home', href: '/' },
		{ name: 'Job Opportunities', href: '/search' },
		{ name: 'Benefits', href: '/benefits' }
	];

	const { MediaContextProvider, Media } = createMedia({
		breakpoints: {
			mobile: 0,
			tablet: 900,
			computer: 1240
		}
	});

	const pPackContent = [
		'As a premier engineering and applications company with expertise in a multitude of product categories, Mestek, Inc.’s esteemed brands provide better engineered solutions to our customers. With continuous improvement of our engineering and manufacturing processes, we are devoted to providing our customers with the highest quality they’ve come to expect from our brands.',
		'Mestek is committed to attracting and maintaining a diverse, dynamic workforce that encourages personal and professional growth for both the individual and team success. If you are looking for an environment where innovation and creativity are cultivated within a respectful, family friendly atmosphere, then consider joining the Mestek team.'
	];

	const pMapContent = [
		'Click on a highlighted state to view our current career openings.'
	];

	const videos = [
		{
			src: 'IxiouDXiq3M',
			name: 'Jennifer A.',
			title: 'Product Manager',
			text: 'I have worked here for over 25 years, starting in our Customer Service department. Even though this is a very large company, it’s got more of a small company feel. One of the things I love about my job is that every day is different. There’s lots of great people here!'
		},
		{
			src: 'o0laXtBeANY',
			name: 'Janitza R.',
			title: 'Shipping/Receiving Clerk and Material Handler',
			text: 'Here at Mestek, there are many opportunities for growth, to become a supervisor or manager. The qualities we look for are a go-getter, somebody that is willing to take the lead on things, to be a team leader and to be selfless. We are a great team to work with! I want to retire here; I don’t want to go anywhere else.'
		},
		{
			src: 'sPH6fb0uUkg',
			name: 'Dennis C.',
			title: 'Head of Material Handlers',
			text: 'I started working for Mestek in 2005. Mestek is a great company to grow up with. In. my case, I started at the line like everybody else, doing the boxes. Now, I’m the Head of Material Handlers because I grew up in the company, learning how to do my job and do it safely. My coworkers are my family! I see myself being here the rest of my days. I love this company; they give me a lot, so I’m grateful.'
		}
	];

	return (
		<Layout
			media={Media}
			mediaContextProvider={MediaContextProvider}
			logoSrc={mestekLogo}
			menuItems={menuItems}
		>
			{' '}
			<Helmet>
				<title>{'Mestek Careers'}</title>
				<meta
					name="description"
					content={
						'Explore job opportunities at Mestek, Inc., a family of manufacturers of HVAC equipment and automated manufacturing machinery with a legacy of innovation.'
					}
				/>
				<link
					rel="icon"
					type="image/png"
					href={favicon}
					sizes="32x32"
				/>
			</Helmet>
			<HeroSectionComponent media={Media} />
			<TextBlock
				h2={'Leading The Pack Since 1946'}
				color={'#000000'}
				p={pPackContent}
			/>
			<div
				style={{
					background: '#F4F4F4',
					display: 'flex',
					flexDirection: 'column',
					paddingBottom: '4rem'
				}}
			>
				<TextBlock
					color={'#333333'}
					h2={'Current Job Openings By State'}
					p={pMapContent}
				/>
				<div
					className={'container-xxl map'}
					style={{ background: '#F4F4F4' }}
				>
					<USMap data={data} />
				</div>
			</div>
			<TextBlock
				h2={'WHAT MESTEK EMPLOYEES ARE SAYING'}
				color={'#000000'}
				p={[
					'Hear stories of what it’s like to work at Mestek and why you should join our growing team!'
				]}
			/>
			<div
				className={'container-xxl pb4 content r'}
				style={{
					gap: '40px',
					flexWrap: 'wrap',
					justifyContent: 'center'
				}}
			>
				{videos.map((video) => {
					return (
						<div
							className={'r'}
							style={{
								maxWidth: '375px',
								width: '100%'
							}}
						>
							<div className="c" style={{ gap: '25px' }}>
								<iframe
									width="375"
									height="247"
									src={
										'https://www.youtube.com/embed/' +
										video.src
									}
									style={{ width: '100%' }}
									frameBorder="0"
								></iframe>
								<div className="c" style={{}}>
									<p
										style={{
											fontSize: '24px',
											margin: 0,
											fontWeight: 700,
											color: '#212529',
											letterSpacing: '-0.44px'
										}}
									>
										{video.name}
									</p>
									<p
										style={{
											fontSize: '18px',
											margin: 0,
											fontWeight: 400,
											color: '#212529',
											letterSpacing: '-0.44px'
										}}
									>
										{video.title}
									</p>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '24px'
									}}
								>
									<p
										style={{
											fontSize: '20px',
											margin: 0,
											fontWeight: 400,
											color: '#212529',
											letterSpacing: '-0.44px'
										}}
									>
										{video.text}
									</p>
									<a
										href="/search"
										className="join-btn"
										style={{
											border: 'solid 1px #9A3650',
											color: '#9A3650',
											fontWeight: 700,
											fontSize: '16px',
											width: 'fit-content',
											borderRadius: '3px',
											padding: '8px',
											transition: 'all 0.2s'
										}}
									>
										Join the Mestek Team
									</a>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className={'container-xxl py6 py5-lg content'}>
				<div className={'c'}>
					<div className={'r-cr-lg ac-lg'}>
						<div className={'c w50 w100-lg p5 py3-lg px0-lg jc'}>
							<img
								src={employee1}
								alt={
									'Image of Mestek Employee in the workplace'
								}
							/>
						</div>
						<div className={'c w50 w100-lg p5 py3-lg px0-lg jc'}>
							<h2>72 years of premier engineering</h2>
							<p>
								While technology and markets have changed since
								1946, the commitment to customer service and
								innovation is as important today as it was in a
								rented garage in Westfield.
							</p>
						</div>
					</div>
					<div className={'r-c-lg ac-lg'}>
						<div className={'c w50 w100-lg p5 py3-lg px0-lg jc'}>
							<h2>Commitment to our clients</h2>
							<p>
								Our focus has always been on consistently
								manufacturing and delivering superior quality,
								solutions-based hydronic heating equipment to
								residential and commercial customers across
								North America.
							</p>
						</div>
						<div className={'c w50 w100-lg p5 py3-lg px0-lg jc'}>
							<img
								src={employee2}
								alt={
									'Image of Mestek Employee in the workplace'
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<JobAlerts />
		</Layout>
	);
};
export default IndexPage;
