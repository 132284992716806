import React, { CSSProperties } from 'react';

const TextBlock = (props: { h2: string; p: Array<string>, color: string}) => {
	const textStyle: CSSProperties = {
		fontFamily: 'Open Sans',
		fontStyle: 'normal',
		textAlign: 'center',
		marginTop: '3rem',
		color: props.color
	};
	const h2Style: CSSProperties = {
		...textStyle,
		fontWeight: 700,
		fontSize: '38px',
		lineHeight: '52px',
		textTransform: 'uppercase',
		color: props.color
	};
	const pStyle: CSSProperties = {
		...textStyle,
		fontWeight: 400,
		fontSize: '20px',
		lineHeight: '27px',
		color: props.color
	};

	const containerStyle: CSSProperties = {
		marginTop: '5rem',
		marginBottom: '5rem'
	};

	return (
		<>
			<section>
				<div className={'container-lg'} style={containerStyle}>
					<h2 style={h2Style}>{props.h2}</h2>
					{props.p.map((pText) => {
						return <p style={pStyle}>{pText}</p>;
					})}
				</div>
			</section>
		</>
	);
};

export default TextBlock;
