import React, { CSSProperties, FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './HeroSection.scss';

// @ts-ignore
import { HeroSearch } from 'career-page-components';

import heroBackground from '../../images/hero-image.png';

import dadancoLogo from '../../images/dadanco_logo.svg';
import awvLogo from '../../images/awv_logo.svg';
import linelLogo from '../../images/linel_logo.svg';
import formtekLogo from '../../images/formtek_logo.svg';
import mastekMLogo from '../../images/mestek_m_logo.svg';
import mcdlgLogo from '../../images/mcdlg_logo.svg';
import mestexLogo from '../../images/mestex_mc_logo.svg';

const logos = [
	{
		alt: 'Dandanco Logo',
		url: dadancoLogo
	},
	{
		alt: 'AWV Logo',
		url: awvLogo
	},
	{
		alt: 'Linel Logo',
		url: linelLogo
	},
	{
		alt: 'Formtek Logo',
		url: formtekLogo
	},
	{
		alt: 'Mastek Machinery Logo',
		url: mastekMLogo
	},
	{
		alt: 'MCDLG Logo',
		url: mcdlgLogo
	},
	{
		alt: 'Mestex Logo',
		url: mestexLogo
	}
];
const HeroSectionComponent = (props: { media: any }) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const Media: any = props.media;

	const heroStyle: CSSProperties = {
		background: `linear-gradient(0deg, rgba(24, 23, 23, 0.5), rgba(24, 23, 23, 0.5)), url(${heroBackground}) no-repeat`,
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	};

	const logoContainerImgStyle: CSSProperties = {
		margin: '35px 30px',
		maxWidth: 125
	};
	const h1Style: CSSProperties = {
		fontFamily: 'Open Sans',
		fontStyle: 'normal',
		fontWeight: 800,
		fontSize: '48px',
		lineHeight: '65px',
		color: '#333333',
		background: 'rgba(255,255,255,0.86)',
		marginTop: 10,
		marginBottom: 8,
		textTransform: 'uppercase',
		padding: '2px 22px',
		width: 'fit-content'
	};
	const h2Style: CSSProperties = {
		fontFamily: 'Open Sans',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '27px',
		lineHeight: '36px',
		color: '#333333',
		background: 'rgba(255,255,255,0.86)',
		marginTop: 0,
		textTransform: 'uppercase',
		padding: '2px 22px',
		width: 'fit-content'
	};
	return (
		<section className={'hero-section c'} style={heroStyle}>
			<div className={'icons d-n-xxl r w100 h100'}>
				<div className={'container-xxl r jc ac fww'}>
					{logos.map((logo: any) => {
						return (
							<img
								style={logoContainerImgStyle}
								src={logo.url}
								alt={logo.alt}
							/>
						);
					})}
				</div>
			</div>
			<div className={'container pt6'}>
				<h1 style={h1Style}>Working at Mestek</h1>
				<h2 style={h2Style}>Experience Based Innovation</h2>
				<p className={'hero-form'}>
					<span>Search Openings</span>
					<HeroSearch
						data={data}
						options={{
							submitButtonText: 'Search Roles'
						}}
						apiKey={process.env.GMAPS_KEY || 'AIzaSyBFD0QXY-CaqBkfIWECnIUFGzX-KXqj58g'}
					/>
				</p>
			</div>
		</section>
	);
};

export default HeroSectionComponent;
